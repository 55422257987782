<template>
  <div>
    <ledger5Logo />

    <div v-if="askFor == 'password'">
      <v-card flat width="550" class="mx-auto mt-4">
        <v-card-title>
          <span class="primary--text display-1 ml-3 mt-2 mb-2">Sign In</span>
          <v-progress-linear
            v-if="showProgressBar"
            indeterminate
            color="success"
          ></v-progress-linear>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="2" />

              <v-col cols="8" class="mt-4">
                <v-text-field
                  rounded
                  v-model="email"
                  :rules="emailRules"
                  validate-on-blur
                  prepend-icon="mdi-account-circle"
                  label="Username (E-Mail)"
                  outlined
                  dense
                />
                <v-text-field
                  rounded
                  v-model="password"
                  :rules="passwordRules"
                  validate-on-blur
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  prepend-icon="mdi-lock"
                  label="Password"
                  outlined
                  dense
                  @click:append="showPassword = !showPassword"
                />
                <p v-if="notification" class="error--text">
                  {{ notification }}
                </p>
                <div class="text-center">
                  <v-btn
                    rounded
                    depressed
                    color="success text--white"
                    @click="loginUser"
                    width="304"
                    class="ml-8"
                    >Sign In</v-btn
                  >
                  <v-btn
                    text
                    plain
                    class="caption text-decoration-underline font-weight-light ml-5"
                    :to="`/forgotPassword`"
                    >Forgot password?</v-btn
                  >
                  <!-- <p class="ml-5">
                    <v-card-text class="primary--text">OR</v-card-text>
                  </p>
                  <v-btn
                    rounded
                    depressed
                    color="info text--primary"
                    :to="`/register`"
                    width="304"
                    class="ml-8 mt-2 mb-6"
                    >Create Account</v-btn
                  > -->
                </div>
              </v-col>

              <v-col cols="2" />
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- Second Factor Required -->

    <div v-if="askFor == '2fa'">
      <v-card flat width="550" class="mx-auto mt-2">
        <v-card-title>
          <span class="primary--text display-1 ml-3 mt-2 mb-2"
            >Secure Sign In</span
          >
          <v-progress-linear
            v-if="showProgressBar2fa"
            indeterminate
            color="success"
          ></v-progress-linear>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit="confirmMfaSignIn"
            onSubmit="return false;"
          >
            <br />
            <v-row>
              <v-col cols="2"></v-col>

              <v-col cols="8">
                <v-card-text
                  class="primary--text font-weight-medium pl-13 mt-n8"
                  >Enter the one-time passcode found in your mobile
                  authenticator app to sign in to Ledger5.
                </v-card-text>
                <v-text-field
                  rounded
                  v-model="code"
                  :rules="numberRules"
                  validate-on-blur
                  prepend-icon="mdi-lock"
                  label="enter 6-digit passcode"
                  outlined
                  dense
                />
                <p v-if="notification2FA" class="error--text">
                  {{ notification2FA }}
                </p>
                <v-btn
                  rounded
                  depressed
                  width="304"
                  class="ml-8 mt-2 mb-6"
                  color="success text--white"
                  @click="confirmMfaSignIn"
                  >Enter</v-btn
                >
              </v-col>

              <v-col cols="2"></v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- New Password Required -->

    <div v-if="askFor == 'newPassword'">
      <v-card flat width="550" class="mx-auto mt-4">
        <v-card-title>
          <span class="primary--text display-1 ml-3 mt-2 mb-2">
            New Password required ...
          </span>
          <v-progress-linear
            v-if="showProgressBarNewPw"
            indeterminate
            color="success"
          ></v-progress-linear>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit="confirmNewPw"
            onSubmit="return false;"
          >
            <v-row>
              <v-col cols="2" />

              <v-col cols="8" class="mt-4">
                <v-card-text
                  class="primary--text font-weight-medium pl-13 mt-n8"
                  >Please enter a new Password for your Ledger5 account.
                </v-card-text>
                <v-text-field
                  rounded
                  v-model="email"
                  :rules="emailRules"
                  prepend-icon="mdi-account-circle"
                  label="Username (E-Mail)"
                  outlined
                  dense
                />
                <v-text-field
                  rounded
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  validate-on-blur
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  prepend-icon="mdi-lock"
                  label="Password"
                  @click:append="showPassword = !showPassword"
                  required
                  outlined
                  dense
                />
                <v-text-field
                  rounded
                  v-model="rePassword"
                  :type="showRePassword ? 'text' : 'password'"
                  :rules="[passwordConfirmationRule]"
                  validate-on-blur
                  :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  prepend-icon="mdi-lock"
                  label="Confirm Password"
                  @click:append="showRePassword = !showRePassword"
                  required
                  outlined
                  dense
                />
                <v-btn
                  rounded
                  depressed
                  color="success text--white"
                  @click="confirmNewPw"
                  width="304"
                  class="ml-8 mt-4 mb-6"
                  >Enter</v-btn
                >
              </v-col>

              <v-col cols="2" />
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import ledger5Logo from '@/components/Ledger5Logo.vue'

export default {
  name: 'Login',

  components: {
    ledger5Logo
  },

  data() {
    return {
      user: null,
      showProgressBar: false,
      showProgressBar2fa: false,
      showProgressBarNewPw: false,
      askFor: 'password',
      valid: true,
      email: this.$route.query.email,
      password: '',
      rePassword: '',
      showPassword: false,
      showRePassword: false,
      notification: '',
      notification2FA: '',
      code: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v =>
          (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(v) && v.length >= 8) ||
          'Uppercase, lowercase, Number and at least 8 Characters req.'
      ],
      numberRules: [
        v => !!v || 'Code is required',
        v =>
          (/^(?=.*\d).+$/.test(v) && v.length == 6) ||
          'Code must be 6 characters'
      ]
    }
  },

  methods: {
    async loginUser() {
      if (this.$refs.form.validate()) {
        this.notification = ''
        this.showProgressBar = true
        try {
          this.user = await Auth.signIn(this.email, this.password)
          if (this.user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            console.log('cognitoUser at MFA pre Login:', this.user)
            this.showProgressBar = false
            this.askFor = '2fa'
          } else if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            console.log('cognitoUser at NEW-PW pre Login:', this.user)
            this.showProgressBar = false
            this.askFor = 'newPassword'
          } else {
            console.log('cognitoUser at Login:', this.user)
            this.$store.dispatch('login', this.user)
            this.showProgressBar = false
            if (
              this.user.attributes['custom:role'] === 'TenAdm' ||
              this.user.attributes['custom:role'] === 'TenAdv'
            ) {
              this.$store.commit('INIT_CLIENTS_STATE')
              this.$router.push('/advisor/dashboard')
            } else if (this.user.attributes['custom:role'] === 'TenClt') {
              this.$router.push('/client/dashboard')
            } else {
              this.$store.dispatch('logout')
            }
          }
        } catch (err) {
          console.log('PRE LOGIN FAILED', err)
          this.showProgressBar = false
          this.notification = err.message
        }
      }
    },

    async confirmMfaSignIn() {
      if (this.$refs.form.validate()) {
        this.showProgressBar2fa = true
        this.notification2FA = ''
        await Auth.confirmSignIn(this.user, this.code, 'SOFTWARE_TOKEN_MFA')
          .then(() => Auth.currentAuthenticatedUser())
          .then(cognitoUser => {
            console.log('cognitoUser at Login:', cognitoUser)
            this.$store.dispatch('login', cognitoUser)
            this.showProgressBar2fa = false
            if (
              cognitoUser.attributes['custom:role'] === 'TenAdm' ||
              cognitoUser.attributes['custom:role'] === 'TenAdv'
            ) {
              this.$store.commit('INIT_CLIENTS_STATE')
              this.$router.push('/advisor/dashboard')
            } else if (cognitoUser.attributes['custom:role'] === 'TenClt') {
              this.$router.push('/client/dashboard')
            } else {
              this.$store.dispatch('logout')
            }
          })
          .catch(err => {
            this.showProgressBar2fa = false
            this.$refs.form.reset()
            console.log('LOGIN FAILED:', err)
            this.notification2FA = err.message
          })
      }
    },

    async confirmNewPw() {
      if (this.$refs.form.validate()) {
        this.showProgressBarNewPw = true
        await Auth.completeNewPassword(this.user, this.password)
          .then(() => Auth.currentAuthenticatedUser())
          .then(cognitoUser => {
            console.log('cognitoUser at Login:', cognitoUser)
            this.$store.dispatch('login', cognitoUser)
            this.showProgressBarNewPw = false
            if (
              cognitoUser.attributes['custom:role'] === 'TenAdm' ||
              cognitoUser.attributes['custom:role'] === 'TenAdv'
            ) {
              this.$store.commit('INIT_CLIENTS_STATE')
              this.$router.push('/advisor/dashboard')
            } else if (cognitoUser.attributes['custom:role'] === 'TenClt') {
              this.$router.push('/client/dashboard')
            } else {
              this.$store.dispatch('logout')
            }
          })
          .catch(err => {
            this.showProgressBarNewPw = false
            this.$refs.form.reset()
            console.log('LOGIN FAILED:', err)
            const notification = {
              type: 'error',
              message: err.message
            }
            this.$store.dispatch('addNotification', notification)
          })
      }
    }
  },

  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.rePassword || 'Passwords do not match'
    }
  }
}
</script>

<style lang="scss" scoped></style>
